import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { KYC } from './kyc/kyc';

/**
 * ApplicationKYC component is responsible for rendering the KYC (Know Your Customer) form.
 * It fetches the KYC data, updates it, and displays the questions to the user.
 *
 * @returns {JSX.Element} The rendered component.
 */
const MyPagesKYC = () => {
    const [isSuccess, setIsSuccess] = useState(false);

    const success = () => {
        setIsSuccess(true);
    };

    if (isSuccess) {
        return (
            <div className="kyc-success">
                <h2 className="kyc-success__title">Tack för att du har skickat in dina uppgifter</h2>
                <p className="kyc-success__text" style={{ marginBottom: '1rem' }}>Du kan nu gå vidare till översikten.</p>
                <a className="et_pb_button cgi-submit-input" href={CGIAjax.myPagesOverview}>Till översikten</a>
            </div>
        );
    }

    return (
        <KYC onSuccess={success} action="post_kyc_answers" />
    );
};

document.addEventListener('DOMContentLoaded', () => {
    const kycDiv = document.getElementById('my-pages-kyc');

    if (kycDiv) {
        const root = createRoot(kycDiv);
        root.render(
            <div className="application-form-wrapper application-loader-wrapper">
                <div className="application-form">
                    <MyPagesKYC />
                </div>
            </div>
        );
    }
});
